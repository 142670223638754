<template>
  <div>
    <el-table id="allTable" :data="allTableData" border height="500" style="width: 100%">
      <el-table-column prop="month" label="统计时间" width="180" align="center"></el-table-column>
      <el-table-column prop="smpl_value" label="总款数" width="180" align="center">
        <template v-slot="scope">
          <span>{{ parseInt(scope.row.newValue) + parseInt(scope.row.oldValue) + parseInt(scope.row.modifyValue) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="newValue" label="新款款数" align="center"></el-table-column>
      <el-table-column prop="modifyValue" label="修改款数" sortable align="center"></el-table-column>
      <el-table-column prop="oldValue" label="老款款数" sortable align="center"></el-table-column>
      <el-table-column prop="all_smpl_value" label="样品个数" sortable align="center"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import htmlToExcel from '@/plugins/htmlToExcel';
import helper from '@assets/js/helper';

export default {
  props: ['tableData'],
  name: 'all',
  data() {
    return {};
  },
  computed: {
    allTableData() {
      return this.tableData;
    }
  },
  methods: {}
};
</script>

<style scoped></style>
