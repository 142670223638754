<template>
  <div>
    <el-table id="animalTable" :data="animalTableData" height="500" border style="width: 100%">
      <el-table-column prop="month" label="统计时间" width="180" align="center"></el-table-column>
      <el-table-column prop="dog_num" label="狗玩具款数" align="center"></el-table-column>
      <el-table-column prop="dog_value" label="狗玩具个数" sortable align="center"></el-table-column>
      <el-table-column prop="cat_num" label="猫玩具款数" sortable align="center"></el-table-column>
      <el-table-column prop="cat_value" label="猫玩具个数" sortable align="center"></el-table-column>
      <el-table-column prop="supplies_num" label="用品款数" sortable align="center"></el-table-column>
      <el-table-column prop="supplies_value" label="用品个数" sortable align="center"></el-table-column>
      <el-table-column prop="bed_num" label="床款数" sortable align="center"></el-table-column>
      <el-table-column prop="bed_value" label="床个数" sortable align="center"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import htmlToExcel from '@/plugins/htmlToExcel';
import helper from '@assets/js/helper';

export default {
  props: ['tableData'],
  name: 'animal',
  data() {
    return {};
  },
  computed: {
    animalTableData() {
      return this.tableData;
    }
  },
  methods: {}
};
</script>

<style scoped></style>
