<template>
  <el-card>
    <el-row>
      <el-col :span="24" style="margin-bottom: 20px">
        <div class="diagramTitle">
          <div class="diagramTitle" style="margin-right: 20px">
            <span style="font-size: 20px; margin-right: 10px">统计时间:</span>
            <el-date-picker
              style="width: 150px"
              v-model="selectedMonth"
              value-format="timestamp"
              type="month"
              clearable
              placeholder="选择月份"
            >
            </el-date-picker>
          </div>
        </div>
      </el-col>
      <el-col :span="24">
        <el-row :gutter="100">
          <el-col :span="12">
            <el-card>
              <VueEcharts :option="option1" style="height: 300px"></VueEcharts>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card>
              <VueEcharts :option="option2" style="height: 300px"></VueEcharts>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="24" style="margin-bottom: 20px; margin-top: 20px">
        <el-col :span="24" style="margin-bottom: 20px">
          <div class="diagramTitle">
            <div class="diagramTitle" style="margin-right: 20px">
              <el-button-group>
                <el-button class="buttonText" size="mini" @click="changeRegion(2, 'all')">上海</el-button>
                <el-button class="buttonText" size="mini" @click="changeRegion(17, 'all')">扬州</el-button>
                <el-button class="buttonText" size="mini" @click="changeRegion('', 'all')">全部</el-button>
              </el-button-group>
            </div>
            <div class="diagramTitle" style="margin-right: 20px">
              <el-button class="buttonText" size="mini" @click="exportToExcel('allTable')">导出Excel</el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <all :table-data="tableData"></all>
        </el-col>
      </el-col>
      <el-col :span="24" style="margin-bottom: 20px">
        <div class="diagramTitle">
          <div class="diagramTitle" style="margin-right: 20px">
            <span style="font-size: 26px; margin-right: 10px">产品分类统计:</span>
          </div>
          <div class="diagramTitle" style="margin-right: 20px">
            <span style="font-size: 20px; margin-right: 10px">统计时间:</span>
            <el-date-picker style="width: 150px" v-model="selectedMonth2" type="month" placeholder="选择月份"> </el-date-picker>
          </div>
        </div>
      </el-col>
      <el-col :span="24">
        <el-row :gutter="100">
          <el-col :span="12">
            <el-card>
              <VueEcharts :option="option3" style="height: 300px"></VueEcharts>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card>
              <VueEcharts :option="option4" style="height: 300px"></VueEcharts>
            </el-card>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="24">
        <el-col :span="24" style="margin-bottom: 20px; margin-top: 20px">
          <div class="diagramTitle">
            <div class="diagramTitle" style="margin-right: 20px">
              <el-button-group>
                <el-button class="buttonText" size="mini" @click="changeRegion(2, 'animal')">上海</el-button>
                <el-button class="buttonText" size="mini" @click="changeRegion(17, 'animal')">扬州</el-button>
                <el-button class="buttonText" size="mini" @click="changeRegion('', 'animal')">全部</el-button>
              </el-button-group>
            </div>
            <div class="diagramTitle" style="margin-right: 20px">
              <el-button class="buttonText" size="mini" @click="exportToExcel('animalTable')">导出Excel</el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <animal :tableData="tableData2"></animal>
        </el-col>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import All from '@/views/DataStatistics/ProductValueYieldStatistics/TabChild/All';
import { get } from '@api/request';
import { dataStaticsAPI } from '@api/modules/stat';
import Animal from '@/views/DataStatistics/ProductValueYieldStatistics/TabChild/Animal';
import htmlToExcel from '@/plugins/htmlToExcel';
import helper from '@assets/js/helper';
export default {
  name: 'ProductionClassificationStatistics',
  components: { Animal, All },
  watch: {
    selectedMonth(newValue, oldValue) {
      if (newValue) {
        let date = new Date(newValue);
        this.startTime = newValue / 1000;
        this.endTime = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime() / 1000;
        this.theifDyj = '';
        this.getProcess('all');
      } else {
        this.startTime = '';
        this.endTime = '';
        this.theifDyj = '';
        this.getProcess('all');
      }
    },
    selectedMonth2(newValue, oldValue) {
      if (newValue) {
        let date = new Date(newValue);
        this.startTime = newValue / 1000;
        this.endTime = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime() / 1000;
        this.theifDyj = '';
        this.getProcess('animal');
      } else {
        this.startTime = '';
        this.endTime = '';
        this.theifDyj = '';
        this.getProcess('animal');
      }
    }
  },
  data() {
    return {
      isSeletedMonth: false,
      selectedMonth: '',
      selectedMonth2: '',
      module: '',
      tableData: [],
      tableData2: [],
      theifDyj: '',
      option1: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        title: {
          text: '样品类型数量统计'
        },
        yAxis: {
          type: 'category',
          data: ['样品个数', '老款款数', '修改款数', '新款款数']
        },
        xAxis: {
          type: 'value'
        },
        series: [
          {
            data: [120, 200, 150, 80],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      },
      option2: {
        title: {
          text: '各打样间占比',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 0, name: '上海打样间' },
              { value: 0, name: '扬州打样间' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ],
        label: {
          formatter: function (data) {
            return `${data.name} ${data.value} (${data.percent.toFixed(1)}%)`;
          }
        }
      },
      option3: {
        title: {
          text: '样品分类款数占比',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ],
        label: {
          formatter: function (data) {
            return `${data.name} ${data.value} (${data.percent.toFixed(1)}%)`;
          }
        }
      },
      option4: {
        title: {
          text: '样品分类个数占比',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ],
        label: {
          formatter: function (data) {
            return `${data.name} ${data.value} (${data.percent.toFixed(1)}%)`;
          }
        }
      },
      startTime: '',
      endTime: ''
    };
  },
  mounted() {
    this.getProcess('all');
    this.getProcess('animal');
  },
  methods: {
    changeRegion(region, module) {
      this.theifDyj = region;
      this.getProcess(module);
    },
    exportToExcel(val) {
      htmlToExcel.getExcel(`#${val}`, val + this.module + helper.toTimeDay(new Date().getTime() / 1000));
    },
    // 深拷贝
    deepCopy(obj) {
      let oldObj = JSON.stringify(obj);
      return JSON.parse(oldObj);
    },
    getProcess(module) {
      this.module = module;
      get(dataStaticsAPI.getProcess, {
        startTime: this.startTime,
        endTime: this.endTime,
        module: module,
        beloDeptId: this.theifDyj
      }).then(({ data }) => {
        if (data.code === 0) {
          if (module === 'all') {
            let option1series = new Array(4);
            let map = new Map();
            let list = this.deepCopy(data.data);
            list.forEach(item => {
              let month = item.date;
              item.newValue = 0;
              item.oldValue = 0;
              item.modifyValue = 0;
              if (this.startTime !== '' && this.endTime !== '') {
                if (item.smpl_type === 1) item.newValue = item.smpl_value;
                if (item.smpl_type === 2) item.oldValue = item.smpl_value;
                if (item.smpl_type === 3) item.modifyValue = item.smpl_value;
                item.month = month;
              } else {
                if (map.has(month)) {
                  let repeat = map.get(month);
                  let newVar = item.smpl_value ?? 0 + repeat.smpl_value;
                  if (item.smpl_type === 1) repeat.newValue = newVar;
                  if (item.smpl_type === 2) repeat.oldValue = newVar;
                  if (item.smpl_type === 3) repeat.modifyValue = newVar;
                  repeat.all_smpl_value = item.all_smpl_value + repeat.all_smpl_value;
                  repeat.all_smpl_value2 = item.all_smpl_value2 + repeat.all_smpl_value2;
                  repeat.all_smpl_value17 = item.all_smpl_value17 + repeat.all_smpl_value17;
                  map.set(month, repeat);
                } else {
                  if (item.smpl_type === 1) item.newValue = item.smpl_value;
                  if (item.smpl_type === 2) item.oldValue = item.smpl_value;
                  if (item.smpl_type === 3) item.modifyValue = item.smpl_value;
                  item.month = month;
                  map.set(month, item);
                }
              }
            });
            let option2Val0 = { value: 0, name: '扬州打样间' };
            let option2Val1 = { value: 0, name: '上海打样间' };
            let temp = [];
            let tempObj = '';
            let value17 = 0;
            let value2 = 0;
            if (this.startTime !== '' && this.endTime !== '') {
              list.forEach(item => {
                if (tempObj) {
                  tempObj.newValue = tempObj.newValue + item.newValue;
                  tempObj.modifyValue = tempObj.modifyValue + item.modifyValue;
                  tempObj.oldValue = tempObj.oldValue + item.oldValue;
                  tempObj.all_smpl_value = tempObj.all_smpl_value + item.all_smpl_value;
                } else {
                  tempObj = item;
                }
                option1series[0] = option1series[0] ? item.newValue + option1series[0] : item.newValue;
                option1series[1] = option1series[1] ? item.modifyValue + option1series[1] : item.modifyValue;
                option1series[2] = option1series[2] ? item.oldValue + option1series[2] : item.oldValue;
                option1series[3] = option1series[3] ? item.all_smpl_value + option1series[3] : item.all_smpl_value;
                if (!this.theifDyj) {
                  value17 += item.all_smpl_value17;
                  value2 += item.all_smpl_value2;
                } else {
                  if (item.belo_dept_id === 17) {
                    value17 += item.all_smpl_value;
                  }
                  if (item.belo_dept_id === 2) {
                    value2 += item.all_smpl_value;
                  }
                }
              });
              option2Val0.value = value17;
              option2Val1.value = value2;
              this.option2.series[0].data = [option2Val0, option2Val1];
              temp.push(tempObj);
              this.tableData = temp;
            } else {
              map.forEach(item => {
                temp.push(item);
                option1series[0] = option1series[0] ? item.newValue + option1series[0] : item.newValue;
                option1series[1] = option1series[1] ? item.modifyValue + option1series[1] : item.modifyValue;
                option1series[2] = option1series[2] ? item.oldValue + option1series[2] : item.oldValue;
                option1series[3] = option1series[3] ? item.all_smpl_value + option1series[3] : item.all_smpl_value;
                if (item.belo_dept_id === 17) {
                  option2Val0.value = item.all_smpl_value + option2Val0.value;
                }
                if (item.belo_dept_id === 2) {
                  option2Val1.value = item.all_smpl_value + option2Val1.value;
                }
              });
              this.option2.series[0].data = [option2Val0, option2Val1];
              this.tableData = temp;
            }
            this.option1.series[0].data = option1series;
          } else {
            let option2Val0 = { value: 0, name: '狗玩具款数' };
            let option2Val1 = { value: 0, name: '猫玩具款数' };
            let option2Val2 = { value: 0, name: '用品款数' };
            let option2Val3 = { value: 0, name: '床款数' };
            let option2Val4 = { value: 0, name: '狗玩具个数' };
            let option2Val5 = { value: 0, name: '猫玩具个数' };
            let option2Val6 = { value: 0, name: '用品个数' };
            let option2Val7 = { value: 0, name: '床个数' };
            let map = new Map();
            let list = this.deepCopy(data.data);
            list.forEach(item => {
              let month = item.date;
              if (map.has(month)) {
                let repeat = map.get(month);
                repeat.dog_num = repeat.dog_num + item.dog_num;
                repeat.dog_value = repeat.dog_value + item.dog_value;
                repeat.cat_num = repeat.cat_num + item.cat_num;
                repeat.cat_value = repeat.cat_value + item.cat_value;
                repeat.supplies_num = repeat.supplies_num + item.supplies_num;
                repeat.supplies_value = repeat.supplies_value + item.supplies_value;
                repeat.bed_num = repeat.bed_num + item.bed_num;
                repeat.bed_value = repeat.bed_value + item.bed_value;
                map.set(month, repeat);
              } else {
                item.month = month;
                map.set(month, item);
              }
            });
            let temp = [];
            map.forEach(item => {
              option2Val0.value = item.dog_num + option2Val0.value;
              option2Val4.value = item.dog_value + option2Val4.value;
              option2Val1.value = item.cat_num + option2Val1.value;
              option2Val5.value = item.cat_value + option2Val5.value;
              option2Val2.value = item.supplies_num + option2Val2.value;
              option2Val6.value = item.supplies_value + option2Val6.value;
              option2Val3.value = item.bed_num + option2Val3.value;
              option2Val7.value = item.bed_value + option2Val7.value;
              temp.push(item);
            });
            this.option3.series[0].data = [option2Val0, option2Val1, option2Val2, option2Val3];
            this.option4.series[0].data = [option2Val4, option2Val5, option2Val6, option2Val7];
            this.tableData2 = temp;
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.diagramTitle {
  display: flex;
  align-items: center;
}
.buttonText {
  font-size: 18px;
}
</style>
